import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment-timezone";

import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import {
  getDashboardData as getDashboardDataApi,
  getDashboardFilters as getDashboardFiltersApi,
  generateDashboardViewOnlyLink as generateDashboardViewOnlyLinkApi,
  getDashboardDataExternal as getDashboardDataExternalApi,
  getVideosCSVRequest as getVideosCSVRequestApi,
} from "@kernel-helpers/DatabaseHelpers";
import { ReduxState } from "@kernel-store/reducers";
import {
  EMPTY_FILTER,
  TSavedDashboardFilter,
  setDashboardAggregationParams,
} from "@kernel-store/dashboard/slice";
import { getVideoListRequest } from "@kernel-store/videos/thunk";
import { setCompanyData } from "@kernel-store/auth/thunk";

export function processFilterObject(filterObject: any) {
  if (filterObject === undefined) {
    return undefined;
  }
  const filterObjectModified = JSON.parse(JSON.stringify(EMPTY_FILTER));

  filterObject.metadata_array.forEach((metadataObj: any) => {
    filterObjectModified.metadata_array.push({
      field_id: metadataObj.field_id,
      option_ids: metadataObj.values.map((x: any) => x.value),
    });
  });

  filterObjectModified.assessment_types = filterObject.assessment_types.map(
    (x: any) => x.value,
  );

  filterObjectModified.assessors = filterObject.assessors.map(
    (x: any) => x.value,
  );

  filterObjectModified.groups = filterObject.groups.map((x: any) => x.value);
  filterObjectModified.subject1_risk_levels =
    filterObject.subject1_risk_levels.map((x: any) => x.value);

  if (filterObject.start_date && typeof filterObject.start_date === "string") {
    // eslint-disable-next-line no-param-reassign
    filterObject.start_date = moment(filterObject.start_date);
  }

  if (filterObject.end_date && typeof filterObject.end_date === "string") {
    // eslint-disable-next-line no-param-reassign
    filterObject.end_date = moment(filterObject.end_date);
  }

  if (filterObject.start_date !== null && filterObject.start_date.isValid())
    filterObjectModified.start_date =
      filterObject.start_date.format("MM/DD/YYYY");

  if (filterObject.end_date !== null && filterObject.end_date.isValid())
    filterObjectModified.end_date = filterObject.end_date.format("MM/DD/YYYY");

  if (filterObject.status) {
    filterObjectModified.status = filterObject.status;
  }

  return filterObjectModified;
}

export function unprocessFilterObject(
  filterObject: any,
  dashboardFilters: any,
) {
  if (filterObject === undefined) {
    return undefined;
  }
  // jank deep copy
  const filterObjectModified = JSON.parse(JSON.stringify(EMPTY_FILTER));
  filterObject.metadata_array.forEach((metadataObj: any) => {
    filterObjectModified.metadata_array.push({
      field_id: metadataObj.field_id,
      values: metadataObj.option_ids.map((x: any, i: number) => {
        let metadataValue = null;
        dashboardFilters.metadataOptionList?.some((l: any) => {
          if (l[1] === metadataObj.field_id) {
            return l[3]?.some((a: any) => {
              if (a?.value === x) {
                metadataValue = a;
                return true;
              }
              return false;
            });
          }
          return false;
        });
        if (metadataValue !== null) {
          return metadataValue;
        }
        return {
          label: `Metadata ${x}`,
          value: x,
          key: i,
          parent_option_id: null,
        };
      }),
      groups: [],
    });
  });

  filterObjectModified.assessment_types = filterObject.assessment_types.map(
    (x: any, i: number) => {
      let assessmentType = null;
      dashboardFilters.assessmentTypeOptionList?.some((l: any) =>
        l?.some((assessmentTypes: any) => {
          if (Array.isArray(assessmentTypes)) {
            const isAssessment = assessmentTypes.find((a) => a.value === x);
            if (isAssessment) {
              assessmentType = isAssessment;
            }
            return !!isAssessment;
          }
          if (assessmentTypes?.value === x) {
            assessmentType = assessmentTypes;
            return true;
          }
          return false;
        }),
      );
      if (assessmentType !== null) {
        return assessmentType;
      }
      return {
        label: `Assessment type ${x}`,
        value: x,
        key: i,
      };
    },
  );
  filterObjectModified.assessors = filterObject.assessors.map(
    (x: any, i: number) => {
      let assessor = null;
      dashboardFilters.assessorsOptionList?.some((l: any) =>
        l?.some((assessors: any) => {
          if (Array.isArray(assessors)) {
            const isAssessor = assessors.find((a) => a.value === x);
            if (isAssessor) {
              assessor = isAssessor;
            }
            return isAssessor || false;
          }
          if (assessors?.value === x) {
            assessor = assessors;
            return true;
          }
          return false;
        }),
      );
      if (assessor !== null) {
        return assessor;
      }
      return {
        label: `Assessor ${x}`,
        value: x,
        key: i,
      };
    },
  );
  filterObjectModified.groups = filterObject.groups.map((x: any, i: number) => {
    let group = null;
    dashboardFilters.groupOptionList?.some((l: any) =>
      l?.some((groups: any) => {
        if (Array.isArray(groups)) {
          const isGroup = groups.find((g) => g.value === x);
          if (isGroup) {
            group = isGroup;
          }
          return isGroup || false;
        }
        if (groups?.value === x) {
          group = groups;
          return true;
        }
        return false;
      }),
    );
    if (group !== null) {
      return group;
    }
    return {
      label: `Group ${x}`,
      value: x,
      key: i,
    };
  });
  filterObjectModified.subject1_risk_levels =
    filterObject.subject1_risk_levels.map((x: any, i: number) => ({
      label: x,
      value: x,
      key: i,
    }));

  if (filterObject.start_date)
    filterObjectModified.start_date = moment(filterObject.start_date);
  if (filterObject.end_date)
    filterObjectModified.end_date = moment(filterObject.end_date);

  return filterObjectModified;
}

export const getDashboardDataRequest = createAsyncThunk<
  { dashboardData?: any; shortKey?: string | null },
  {
    assessmentsByFieldParam?: string | null;
    dateGranularityParam?: string | null;
    aggType?: string | null;
    isExternal?: boolean;
    ttl?: number;
  },
  { state: ReduxState }
>(
  "dashboard/getDashboardDataRequest",
  async (
    { assessmentsByFieldParam, dateGranularityParam, aggType, isExternal, ttl },
    { getState, dispatch },
  ) => {
    let state = getState();
    let fieldParam: string | null | undefined = assessmentsByFieldParam;
    let granularityParam: string | null | undefined = dateGranularityParam;
    let dashboardAggType: string | null | undefined = aggType;
    let { filterObject } = state.dashboard;
    if (filterObject === undefined) {
      filterObject = await getDashboardFiltersApi();
    }
    const filterObjectModified = processFilterObject(filterObject);
    const clientTimezone =
      Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess();
    if (fieldParam === null || fieldParam === undefined || fieldParam === "") {
      fieldParam = state.dashboard.dashboardFieldParam;
    }
    if (
      granularityParam === null ||
      granularityParam === undefined ||
      granularityParam === ""
    ) {
      granularityParam = state.dashboard.dashboardGranularity;
    }
    if (
      dashboardAggType === null ||
      dashboardAggType === undefined ||
      dashboardAggType === ""
    ) {
      // eslint-disable-next-line prefer-destructuring
      dashboardAggType = state.dashboard.dashboardAggType;
    }
    dispatch(
      setDashboardAggregationParams({
        dashboardFieldParam: fieldParam,
        dashboardGranularity: granularityParam,
        dashboardAggType,
      }),
    );
    if (isExternal) {
      try {
        const result = await generateDashboardViewOnlyLinkApi(ttl || 365, {
          filter_object: filterObjectModified,
          assessments_by_field_param: fieldParam,
          date_granularity_param: granularityParam,
          client_timezone: clientTimezone,
          agg_type: dashboardAggType,
        }); // TODO(znoland): filters go here
        return { shortKey: result.shortKey };
      } catch (e) {
        return { shortKey: null };
      }
    } else {
      let dashboardData = null;
      try {
        dashboardData = await getDashboardDataApi(
          filterObjectModified,
          fieldParam,
          granularityParam,
          clientTimezone,
          dashboardAggType,
        ); // TODO(znoland): filters go here
      } catch (e: any) {
        console.log("Dashboard data error", e);
        throw e;
      }
      state = getState();
      if (state.authUser.user?.role) {
        dispatch(
          getVideoListRequest({
            userId: state.authUser.user.uid,
            companyId: state.authUser.user.companyId,
            groupId: state.authUser.user.companyId,
          }),
        );
      }

      return { dashboardData };
    }
  },
);

export const getDashboardFiltersRequest = createAsyncThunk<
  {
    dashboardFilters: any;
  },
  undefined,
  { state: ReduxState }
>("dashboard/getDashboardFiltersRequest", async () => {
  let dashboardFilters = null;
  try {
    dashboardFilters = await getDashboardFiltersApi(); // TODO(znoland): filters go here
  } catch (e) {
    console.log("Get dashboard filters error", e);
    throw e;
  }

  return { dashboardFilters };
});

export const setDashboardFilterField = createAsyncThunk<
  void,
  { field: string; value: string },
  { state: ReduxState }
>("dashboard/setDashboardFilterField", async (_, { dispatch }) => {
  dispatch(getDashboardDataRequest({}));
});

export const setDashboardFilterFields = createAsyncThunk<
  void,
  { filterObject: any },
  { state: ReduxState }
>("dashboard/setDashboardFilterFields", async (_, { dispatch }) => {
  dispatch(getDashboardDataRequest({}));
});

export const clearDashboardFilters = createAsyncThunk<
  void,
  undefined,
  { state: ReduxState }
>("dashboard/clearDashboardFilters", async (_, { dispatch }) => {
  dispatch(getDashboardDataRequest({}));
});

export const setDashboardOnboardingFinished = createAsyncThunk<
  { onboardingFinished: string[] },
  { value: string },
  { state: ReduxState }
>(
  "dashboard/setDashboardOnboardingFinished",
  async ({ value }, { getState }) => {
    const state = getState();
    const onboardingFinished = state.dashboard.onboardingFinished
      .filter((val) => val !== value)
      .concat(value);
    const stogareAdapter = TumekeJSModule.get(
      EAdapterNames.Storage,
    ) as IStorageAdapter;
    await stogareAdapter.setItem(
      "onboardingFinished",
      JSON.stringify(onboardingFinished),
    );
    return { onboardingFinished };
  },
);

export const saveDashboardFilter = createAsyncThunk<
  { savedDashboardFilters: TSavedDashboardFilter[] },
  { index: number | null; name: string },
  { state: ReduxState }
>("dashboard/saveDashboardFilter", async ({ index, name }, { getState }) => {
  const state = getState();
  let savedDashboardFilters: TSavedDashboardFilter[] = [
    ...state.dashboard.savedDashboardFilters,
  ];
  if (index === null) {
    savedDashboardFilters.push({ name, filters: state.dashboard.filterObject });
  } else {
    savedDashboardFilters = savedDashboardFilters.map(
      (savedDashboardFilter, idx) => {
        if (index === idx) {
          return { name, filters: state.dashboard.filterObject };
        }
        return savedDashboardFilter;
      },
    );
  }
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  await stogareAdapter.setItem(
    "savedDashboardFilters",
    JSON.stringify(savedDashboardFilters),
  );

  return { savedDashboardFilters };
});

export const deleteDashboardFilter = createAsyncThunk<
  { savedDashboardFilters: any },
  { index: number },
  { state: ReduxState }
>("dashboard/deleteDashboardFilter", async ({ index }, { getState }) => {
  const state = getState();
  const savedDashboardFilters = state.dashboard.savedDashboardFilters.filter(
    (_, idx) => idx !== index,
  );
  const stogareAdapter = TumekeJSModule.get(
    EAdapterNames.Storage,
  ) as IStorageAdapter;
  stogareAdapter.setItem(
    "savedDashboardFilters",
    JSON.stringify(savedDashboardFilters),
  );

  return { savedDashboardFilters };
});

export const getDashboardDataExternal = createAsyncThunk<
  { dashboardData?: any; dashboardFilters?: any; filterObject?: any },
  { shortKey: string },
  { state: ReduxState }
>("dashboard/getDashboardDataExternal", async ({ shortKey }, { dispatch }) => {
  try {
    const data = await getDashboardDataExternalApi(shortKey);
    if (data.companyData) {
      dispatch(
        setCompanyData({
          metadata: [{ id: 0, name: "Assessment Version", options: [] }],
        }),
      );
    }
    if (data.filtersData) {
      dispatch(
        setDashboardAggregationParams({
          dashboardFieldParam: data.filtersData.assessments_by_field_param,
          dashboardGranularity: data.filtersData.date_granularity_param,
          dashboardAggType: data.filtersData.agg_type,
        }),
      );
      const filterObject = unprocessFilterObject(
        data.filtersData.filter_object,
        data.dashboardFilters,
      );

      return {
        dashboardData: data.dashboardData,
        dashboardFilters: data.dashboardFilters,
        filterObject,
      };
    }
  } catch (e) {
    console.log("Get dashboard data external error", e);
    throw e;
  }
  return {};
});

export const exportBulkCSVRequest = createAsyncThunk<
  {},
  undefined,
  { state: ReduxState }
>("dashboard/exportBulkCSVRequest", async (_, { getState }) => {
  const state = getState();
  const { filterObject } = state.dashboard;
  const { videosLoading } = state.videos;
  if (videosLoading) {
    return {};
  }

  const pageSize = 0;
  const pageOffset = 0;
  const search = "";

  try {
    const filterObjectModified = processFilterObject(filterObject);
    await getVideosCSVRequestApi(
      filterObjectModified,
      "",
      "",
      Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(),
      pageSize,
      pageOffset,
      search,
    );
  } catch (e) {
    console.log(e);
    throw e;
  }

  return {};
});
