import { createAsyncThunk } from "@reduxjs/toolkit";

import { IImprovementData } from "@tumeke/tumekejs/lib/utils/interfaces";
import {
  getImprovement as getImprovementApi,
  getImprovements as getImprovementsApi,
  saveImprovement as saveImprovementApi,
  deleteImprovement as deleteImprovementApi,
} from "@kernel-helpers/DatabaseHelpers";
import { ReduxState } from "@kernel-store/reducers";
import { updateImprovement } from "@kernel-store/improvements/slice";
import { getThumbnailsRequest } from "@kernel-store/videos/thunk";
import { processFilterObject } from "@kernel-store/actions";

export const getImprovementsRequest = createAsyncThunk<
  { count: number },
  {
    userId?: string;
    companyId?: string;
    groupId?: string;
    pageSizeArg?: number;
    pageOffsetArg?: number;
    improvementId?: number;
  },
  { state: ReduxState }
>(
  "improvements/getImprovementsRequest",
  async (
    { pageSizeArg, pageOffsetArg, improvementId },
    { getState, dispatch },
  ) => {
    const state = getState();

    let improvements: any = null;
    let improvementsObj: any = null;
    let improvementsCount: number = 0;

    let pageSize = pageSizeArg;
    let pageOffset = pageOffsetArg;
    if (pageSize === undefined) {
      pageSize = state.improvements.pageMetadata.pageSize;
    }
    if (pageOffset === undefined) {
      pageOffset = (state.improvements.pageMetadata.currentPage - 1) * pageSize;
    }

    try {
      if (improvementId) {
        improvementsObj = await getImprovementApi(improvementId);
        improvements = [improvementsObj];
      } else {
        const filterObject = processFilterObject(state.dashboard.filterObject);
        improvementsObj = await getImprovementsApi(
          filterObject,
          pageSize,
          pageOffset,
        );
        improvements = improvementsObj.improvements;
      }

      improvementsCount = improvementsObj.improvementsCount;
    } catch (e) {
      console.log("Get improvements error", e);
      throw e;
    }

    let improvementVideosIds: string[] = [];
    for (let i = 0; i < improvements.length; i += 1) {
      improvementVideosIds = improvementVideosIds.concat(
        improvements[i].videos_before.map((a: any) => a.id),
      );

      dispatch(updateImprovement({ improvementData: improvements[i] }));
    }
    dispatch(
      getThumbnailsRequest({
        videoIds: improvementVideosIds,
        isImprovement: true,
      }),
    );

    return {
      count: improvementId ? state.improvements.count : improvementsCount,
    };
  },
);

export const saveImprovement = createAsyncThunk<
  void,
  {
    improvementData: IImprovementData;
    improvementId?: number;
  },
  { state: ReduxState }
>(
  "improvements/saveImprovement",
  async ({ improvementData, improvementId }, { dispatch }) => {
    let improvement: any;
    try {
      improvement = await saveImprovementApi(improvementData, improvementId);
    } catch (e) {
      console.log("Save improvement error", e);
      throw e;
    }

    dispatch(updateImprovement({ improvementData: improvement }));
    if (improvement?.videos_before.length) {
      dispatch(
        getThumbnailsRequest({
          videoIds: [improvement.videos_before[0].id],
          isImprovement: true,
        }),
      );
    }
  },
);

export const deleteImprovementRequest = createAsyncThunk<
  { improvementIds: number[] },
  { improvementIds: number[] },
  { state: ReduxState }
>("improvements/deleteImprovementRequest", async ({ improvementIds }) => {
  const deletePromises = improvementIds.map((improvementId) =>
    deleteImprovementApi(improvementId),
  );
  try {
    await deletePromises;
  } catch (e) {
    console.log("Delete improvement error", e);
  }

  return { improvementIds };
});
